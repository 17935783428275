import { createRef, useState } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { ChevronDownIc } from '@dsch/dd-icons';
import { useClickOutside } from 'hooks/useClickOutside';
import { media } from 'helpers/breakpoints';

type Variant = 'PRIMARY' | 'DEFAULT';

export interface ISimpleDropdownProps {
  text: ReactNode;
  children: ReactNode;
  isDisabled?: boolean;
  variant?: Variant;
  className?: string;
  showArrow?: boolean;
  align?: 'left' | 'right';
}

interface IText {
  variant: Variant;
  isDisabled: boolean;
}

const Text = styled.div<IText>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme, variant, isDisabled }) => {
    const variantValue =
      variant === 'PRIMARY' ? theme.colors.BLUE : theme.colors.GREY_DARK;
    return isDisabled ? theme.colors.GREY_LIGHT : variantValue;
  }};
  cursor: pointer;
`;

interface IChevron {
  $isMenuOpen: boolean;
  $isDisabled: boolean;
}

const Chevron = styled(ChevronDownIc)<IChevron>`
  margin-left: ${({ theme }) => theme.spacing.S8};
  color: ${({ theme, $isDisabled }) =>
    $isDisabled ? theme.colors.GREY_LIGHT : theme.colors.BLUE};

  transform: rotate(${({ $isMenuOpen }) => ($isMenuOpen ? '180deg' : '0')});
`;

const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownMenu = styled.div<{ align: 'left' | 'right' }>`
  position: absolute;
  z-index: 9999;
  padding: ${({ theme }) => `${theme.spacing.S8} 0 ${theme.spacing.S4}`};
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.getOuterShadow(theme.colors.BLACK)};
  ${({ align }) => (align === 'left' ? 'left: 0' : 'right: 0')};
  margin-top: ${({ theme }) => `${theme.spacing.S2}`};
  ${media.medium} {
    margin-top: 0;
  }
`;

function SimpleDropdown({
  text,
  children,
  variant = 'DEFAULT',
  isDisabled = false,
  className,
  showArrow = true,
  align = 'right',
}: ISimpleDropdownProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = createRef<HTMLDivElement>();

  useClickOutside({
    active: isMenuOpen,
    ref: dropdownRef,
    onClickOutside: () => setIsMenuOpen(false),
  });

  const handleOnMenuOpen = (isOpen: boolean) => {
    if (!isDisabled) {
      setIsMenuOpen(isOpen);
    }
  };

  return (
    <DropdownContainer ref={dropdownRef} className={className}>
      <Text
        onClick={() => handleOnMenuOpen(!isMenuOpen)}
        variant={variant}
        isDisabled={isDisabled}
      >
        {text}{' '}
        {showArrow && (
          <Chevron
            $isMenuOpen={isMenuOpen}
            height={16}
            width={16}
            $isDisabled={isDisabled}
          />
        )}
      </Text>
      {isMenuOpen && (
        <DropdownMenu align={align} onClick={() => handleOnMenuOpen(false)}>
          {children}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
}

export { SimpleDropdown };
